var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "기본정보" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.disabled,
                            expression: "!disabled",
                          },
                        ],
                        attrs: {
                          url: _vm.updateUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.accidentInfo,
                          mappingType: "PUT",
                          label: "기본정보 저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveAccident,
                          btnCallback: _vm.saveCallback,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        rows: 5,
                        label: "재해발생원인",
                        name: "shortPlan",
                      },
                      model: {
                        value: _vm.accidentInfo.accidentCause,
                        callback: function ($$v) {
                          _vm.$set(_vm.accidentInfo, "accidentCause", $$v)
                        },
                        expression: "accidentInfo.accidentCause",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        rows: 5,
                        label: "재발방지대책(공학적)",
                        name: "engineeringRecurrence",
                      },
                      model: {
                        value: _vm.accidentInfo.engineeringRecurrence,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.accidentInfo,
                            "engineeringRecurrence",
                            $$v
                          )
                        },
                        expression: "accidentInfo.engineeringRecurrence",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        rows: 5,
                        label: "재발방지대책(관리적)",
                        name: "managementRecurrence",
                      },
                      model: {
                        value: _vm.accidentInfo.managementRecurrence,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.accidentInfo,
                            "managementRecurrence",
                            $$v
                          )
                        },
                        expression: "accidentInfo.managementRecurrence",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        rows: 5,
                        label: "환경안경팀 의견",
                        name: "managerOpinion",
                      },
                      model: {
                        value: _vm.accidentInfo.managerOpinion,
                        callback: function ($$v) {
                          _vm.$set(_vm.accidentInfo, "managerOpinion", $$v)
                        },
                        expression: "accidentInfo.managerOpinion",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "c-table",
        {
          ref: "tableImpr",
          attrs: {
            title: "개선 목록",
            columns: _vm.gridImpr.columns,
            data: _vm.accidentInfo.accidentImproveModelList,
            gridHeight: _vm.gridHeight,
            merge: _vm.gridImpr.merge,
            selection: "multiple",
            rowKey: "causesNo",
            usePaging: false,
            hideBottom: true,
            isExcelDown: false,
            filtering: false,
            columnSetting: false,
            editable: _vm.editable && !_vm.disabled,
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "customCol"
                    ? [
                        _c(_vm.imprComponent, {
                          tag: "component",
                          attrs: {
                            col: col,
                            props: props,
                            inputEditable: _vm.editable && !_vm.disabled,
                            isImmShow: true,
                            tableKey: "causesNo",
                            ibmTaskTypeCd: "ITT0000040",
                            ibmTaskUnderTypeCd: "ITTU000065",
                          },
                          on: { imprChange: _vm.imprChange },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          label: "LBLADD",
                          showLoading: false,
                          icon: "add",
                        },
                        on: { btnClicked: _vm.addImpr },
                      })
                    : _vm._e(),
                  _vm.editable &&
                  !_vm.disabled &&
                  _vm.accidentInfo.accidentImproveModelList.length > 0
                    ? _c("c-btn", {
                        attrs: {
                          url: _vm.updateCauseUrl,
                          isSubmit: _vm.isSave2,
                          param: _vm.accidentInfo.accidentImproveModelList,
                          mappingType: "PUT",
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveImpr,
                          btnCallback: _vm.saveCallback,
                        },
                      })
                    : _vm._e(),
                  _vm.editable &&
                  !_vm.disabled &&
                  _vm.accidentInfo.accidentImproveModelList.length > 0
                    ? _c("c-btn", {
                        attrs: {
                          label: "LBLREMOVE",
                          showLoading: false,
                          icon: "remove",
                        },
                        on: { btnClicked: _vm.removeImpr },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }