<template>
  <q-form ref="editForm">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- 기본정보 -->
      <c-card title="기본정보" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline>
            <!-- 기본정보 저장 -->
            <c-btn
              v-show="!disabled"
              :url="updateUrl"
              :isSubmit="isSave"
              :param="accidentInfo"
              mappingType="PUT"
              label="기본정보 저장"
              icon="save"
              @beforeAction="saveAccident"
              @btnCallback="saveCallback" 
            />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              :rows="5"
              label="재해발생원인"
              name="shortPlan"
              v-model="accidentInfo.accidentCause">
            </c-textarea>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              :rows="5"
              label="재발방지대책(공학적)"
              name="engineeringRecurrence"
              v-model="accidentInfo.engineeringRecurrence">
            </c-textarea>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              :rows="5"
              label="재발방지대책(관리적)"
              name="managementRecurrence"
              v-model="accidentInfo.managementRecurrence">
            </c-textarea>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              :rows="5"
              label="환경안경팀 의견"
              name="managerOpinion"
              v-model="accidentInfo.managerOpinion">
            </c-textarea>
          </div>
        </template>
      </c-card>
    </div>
    <!-- 개선 목록 -->
    <c-table
      ref="tableImpr"
      title="개선 목록"
      :columns="gridImpr.columns"
      :data="accidentInfo.accidentImproveModelList"
      :gridHeight="gridHeight"
      :merge="gridImpr.merge"
      selection="multiple"
      rowKey="causesNo"
      :usePaging="false"
      :hideBottom="true"
      :isExcelDown="false"
      :filtering="false"
      :columnSetting="false"
      :editable="editable && !disabled"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 추가 -->
          <c-btn v-if="editable && !disabled" label="LBLADD" :showLoading="false"  icon="add" @btnClicked="addImpr" />
          <!-- 저장 -->
          <c-btn
            v-if="editable && !disabled && accidentInfo.accidentImproveModelList.length > 0"
            :url="updateCauseUrl"
            :isSubmit="isSave2"
            :param="accidentInfo.accidentImproveModelList"
            mappingType="PUT"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveImpr"
            @btnCallback="saveCallback" 
          />
          <c-btn v-if="editable && !disabled && accidentInfo.accidentImproveModelList.length > 0" label="LBLREMOVE" :showLoading="false" icon="remove" @btnClicked="removeImpr" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="editable&&!disabled"
            :isImmShow="true"
            tableKey="causesNo"
            ibmTaskTypeCd="ITT0000040"
            ibmTaskUnderTypeCd="ITTU000065"
            @imprChange="imprChange"
          />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>

import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'accident-cause-prevention',
  props: {
    param: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        stepCd: '',
        regUpdateBtnData: {
          title: '사고등록',
          flag: false,
          research: '',
          planResearch: '',
          recResearch: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
    accidentInfo: {
      type: Object,
      default: () => ({
        occurrenceDeptCd: '',
        accidentStatusCd: '',
        accidentCause: '',
        engineeringRecurrence: '',
        managementRecurrence: '',
        accidentImproveModelList: [],
      }),
    },
  },
  data() {
    return {
      editable: true,
      gridImpr: {
        columns: [
          {
            required: true,
            name: 'causeTypeCd',
            field: 'causeTypeCd',
            // 원인구분
            label: 'LBL00001560',
            align: 'center',
            style: 'width:15%',
            type: 'select',
            comboItems: [
              { code: 'ICDC000001', codeName: '기술적' },
              { code: 'ICDC000002', codeName: '교육적' },
              { code: 'ICDC000003', codeName: '관리적' },
            ],
            sortable: false,
          },
          {
            required: true,
            name: 'accidentCause',
            field: 'accidentCause',
            // 사고원인
            label: 'LBL00001561',
            align: 'left',
            type: 'text',
            style: 'width:50%',
            sortable: false,
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: 'LBL00001562',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                // 개선번호/진행상태
                label: 'LBLIMPRNO_PROGRESS',
                align: 'center',
                style: 'width:15%',
                type: 'custom',
                sortable: false
              },
              {
                name: 'improvingMeasures',
                field: 'improvingMeasures',
                // 개선대책
                label: 'LBL00001563',
                align: 'left',
                style: 'width:20%',
                type: 'text',
                sortable: false
              },
            ]
          },
        ],
        
        data: [],
        height: '315px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      imprData: null,
      completeUrl: '',
      updateUrl: '',
      updateCauseUrl: '',
      deleteUrl: '',
      searchUrl: '',
      isSave: false,
      isSave2: false,
      getUrl: '',
    };
  },
  computed: {
    gridHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      numHeight = numHeight - 200
      return numHeight + 'px';
    },
    isEnvSafDept() { 
      return this.$_.indexOf(this.$store.getters.auths, 'SAGI000025') > -1 || this.$store.getters.user.empNo === 'admin'
    },
    disabled() {
      return this.param.stepCd === 'ISPC000005' && !this.isEnvSafDept
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.updateUrl = transactionConfig.sop.iim.accident.process.prevention.update.url
      this.updateCauseUrl = transactionConfig.sop.iim.accident.process.prevention.update.cause.url
      this.deleteUrl = transactionConfig.sop.iim.accident.process.prevention.delete.url
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
    },
    saveAccident() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.accidentInfo.regUserId = this.$store.getters.user.userId
              this.accidentInfo.chgUserId = this.$store.getters.user.userId
              
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveImpr() {
      if(this.$comm.validTable(this.gridImpr.columns, this.accidentInfo.accidentImproveModelList)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.accidentInfo.regUserId = this.$store.getters.user.userId
            this.accidentInfo.chgUserId = this.$store.getters.user.userId
            this.isSave2 = !this.isSave2;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('getDetail');
    },
    addImpr() {
      this.accidentInfo.accidentImproveModelList.splice(0, 0, {
        iimAccidentId: this.param.iimAccidentId,
        causesNo: uid(),
        causeTypeCd: null,
        accidentCause: '',
        improvingMeasures: '',
        sopImprovementIds: '', 
        ibmStepNames: '', 
        editFlag: 'C',
      })
    },
    removeImpr() {
      let selectData = this.$refs['tableImpr'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$refs['tableImpr'].$refs['compo-table'].clearSelection();
              this.$emit('getDetail');
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
